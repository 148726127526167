/*
 * routes
 */
export const routePathNames = {
  dashboard: "/",
  notFound: "/404",
  maintenance: "/wartung",
  category: "kategorie/",
  shop: "/shop",
  news: "/nachrichten",
  article: "/news/artikel/",
  product: "/produkte/produkt/",
  products: "/produkte/",
  search: "suche",
  weekPlanner: "/wochenplaner/",
  order: "/bestellungen/bestellung/",
  orders: "/bestellungen",
  login: "/login",
  infoCenter: "/info",
  termsAndConditions: "/agb",
  passwordReset: "/passwort-setzen",
  confirmRegister: "/registrierung-abschliessen",
  imprint: "/impressum",
  privacy: "/datenschutz",
  rightOfWithdrawal: "/widerrufsbelehrung",
  cart: "/warenkorb",
  carts: "/warenkoerbe",
  contact: "/kontakt",
  checkoutSuccess: "/bestellung-erfolgreich",
  userAccount: "/konto/",
  userProfileEdit: "/benutzer/bearbeiten/",
  userProfileAdd: "/benutzer/anlegen/",
  orderConfirmation: "/bestellung-erfolgreich",
  userProfilesAdministration: "/benutzerverwaltung/",
  priceTagList: "/etikettenliste",
  favouriteList: "/favoritenlisten/",
  orderingInformation: "/bestellinformationen",
  standingOrders: "/dauerauftraege",
  stocktakings: "/inventuren",
  stocktaking: "/inventur",
  support: "/support",
  impulse: "/impulse",
  loginRequest: "/zugang-anfordern",
  quickOrder: "/schnellbestellung",
  downloads: "/downloads",
};

/**
 * search parameter names for location
 * e.g. for a search term, pagination, etc.
 * filterConjunction: control char to add multiple values of one filter to the URL and be able to split them again. It is strongly advised to stay with the "|" due to the fact, that it is a reserved char for this project
 * searchTerm: parameter for search input
 * page: parameter for pagination
 * deliveryDate: parameter for selected cart delivery date
 * sortBy: sort parameter the results are ordered by
 * sortDirection: sort direction, can be either "asc" or "desc"
 * categoryKey is the current active category, which will be used to construct breadcrumb on PDP
 */
export const locationSearchQueryParameter = {
  filterConjunction: "|",
  searchTerm: "search",
  sku: "sku",
  page: "page",
  deliveryDate: "deliveryDate",
  deliveryDateFrom: "deliveryDateFrom",
  deliveryDateTo: "deliveryDateTo",
  sortBy: "sortBy",
  sortDirection: "sortDirection",
  breadcrumbReference: "ck",
  searchFilter: "searchFilter",
  invoiceNumber: "invoiceNumber",
  documentType: "documentType",
  skipAvailabilityCheck: "skipAvailabilityCheck",
  brandName: "herstellermarkenpreislistentextfuerausgabe",
  cartNote: "cartNote",
  fileFormat: "format",
  overwriteDeliveryDate: "overwriteDeliveryDate",
};

/*
 * mapping for contentful content type names
 * contentType: name of the content type group in contentful
 * inclusion: Object to filter a specific contentType directly on API level
 * limit: alter the contentful default limit (100)
 */
export const contentfulContentTypes = {
  orderConfirmation: {
    contentType: "generic-page",
    inclusion: {
      "fields.slug[in]": routePathNames.orderConfirmation.replace("/", ""),
    },
  },
  infoCenterFolder: {
    contentType: "info-center-folder",
    limit: 1000,
  },
  webshop: {
    contentType: "webshop_startpage",
  },
  webshopCategory: {
    contentType: "webshop-category",
  },
  webshopCategoryNews: {
    contentType: "webshop-category-news",
  },
  webshopCategoryVideo: {
    contentType: "webshop-category-video",
  },
  webshopCategoryDownload: {
    contentType: "webshop-category-download",
  },
  webshopDetailPageBanner: {
    contentType: "webshop-detail-page-banner",
  },
  webshopDashboardTeaser: {
    contentType: "webshop-dashboard-teaser",
  },
  webshopDashboardOffer: {
    contentType: "webshop-dashboard-offer",
  },
  webshopProductInformation: {
    contentType: "webshop-product-information",
  },
  newsEntry: {
    contentType: "news-item",
  },
  newsCategory: {
    contentType: "news-category",
  },
  termsAndConditions: {
    contentType: "generic-page",
    inclusion: {
      "fields.slug[in]": routePathNames.termsAndConditions.replace("/", ""),
    },
  },
  imprint: {
    contentType: "generic-page",
    inclusion: {
      "fields.slug[in]": routePathNames.imprint.replace("/", ""),
    },
  },
  privacy: {
    contentType: "generic-page",
    inclusion: {
      "fields.slug[in]": routePathNames.privacy.replace("/", ""),
    },
  },
  contentOrder: {
    contentType: "entry-reference-order",
  },
  orderingInformation: {
    contentType: "generic-page",
    inclusion: {
      "fields.slug[in]": routePathNames.orderingInformation.replace("/", ""),
    },
  },
  supportFaq: {
    contentType: "support-faq",
  },
  supportTutorial: {
    contentType: "support-tutorial",
  },
  impulsaktion: {
    contentType: "impulsaktion",
  },
  adProductListingTop: {
    contentType: "ad-product-listing-top",
  },
  adProductListing: {
    contentType: "ad-product-listing",
  },
  adWebshopDashboard: {
    contentType: "ad-webshop-dashboard",
  },
  portalTip: {
    contentType: "portal-tip",
  },
};

/*
 * special category keys to display / filter for
 */
export const routeProductCategoryKeys = {
  fruitsAndVeggies: "17500",
  novelty: "10000",
  promotion: "15000",
  displays: "7575000",
  preorder: "7570000",
  others: "1005100000",
  bioladenNovelty: "7625000",
  currentPromotion: "7540000",
  nextPromotion: "7550000",
  christmasMeatPromotion: "22500",
  cheese: "580000",
  easterMeatPromotion: "575000",
  easterPromotion: "7562500",
  barbecuePromotion: "7551100",
  abo: "19000",
};

/*
 * range for the datepicker
 * unit: days
 * relation: today
 * minDays: the amount of days backwards
 * maxDays: the amount if days forward
 */
export const datePickerRange = {
  minDays: 1,
  maxDays: 365,
};

/**
 * item limits for pages &/ pagination
 * used for visible items
 * and also for API requests
 */
export const itemsPerPage = {
  news: 10,
  products: 75,
  weekplannerProducts: 50,
  orderList: 10,
  productInformation: 6,
  standingOrders: 10,
  stocktakings: 10,
  stocktakingItems: 500,
  supportFaq: 1000,
  supportTutorials: 1000,
};

/**
 * map of spryker internal values
 */
export const sprykerCompareValues = {
  termsAndConditionsConsentPermission: "CompanyUserAgbConsentPermissionPlugin",
};

/**
 * map of spryker glossary keys for components
 */
export const sprykerGlossaryKeys = {
  ingredientsLegend: "catalog.string.zutaten_legende",
  ingredients: "catalog.string.zutaten",
  herbalNotice: "catalog.string.herbal_certificate_notice",
};

/*
 * message texts and config for ant design message component
 */
export const messageData = {
  success: {
    auth: {
      login: {
        content: "Login erfolgreich.",
        className: "ant-message-login-success",
        duration: 1,
      },
      businessUnit: {
        content: "Die Filiale {{businessUnit}} wurde ausgewählt.",
      },
      setPassword: {
        content: "Passwort erfolgreich geändert!",
      },
      resetPassword: {
        content:
          "Passwort erfolgreich zurückgesetzt! Wenn diese E-Mail vorhanden ist, erhalten Sie einen Link zum Zurücksetzen!",
      },
      updatePassword: {
        content: "Eine E-Mail zum Ändern des Passwortes wurde versandt",
        duration: 3,
      },
      resendRegisterConfirmation: {
        content:
          "Eine E-Mail zur Bestätigung der Registrierung wurde versendet.",
      },
    },
    scanner: {
      scannerReady: {
        content: "Produkte können jetzt gescannt werden.",
      },
      revertScan: {
        content: "Die ursprüngliche Menge wurde wiederhergestellt.",
      },
      addedItem: {
        content: "Artikel {{name}} wurde ein Mal in den Warenkorb gelegt.",
        duration: 2,
      },
      updateQuantity: {
        content: "Artikel {{name}} wurde erfolgreich in den Warenkorb gelegt.",
        duration: 2,
      },
      deleteQuantity: {
        content:
          "Artikel {{name}} wurde erfolgreich aus dem Warenkorb entfernt.",
        duration: 2,
      },
    },
    cart: {
      deleteCart: {
        content: "Der ausgewählte Warenkorb wurde erfolgreich gelöscht.",
      },
      deleteItem: {
        content: "Der Artikel wurde erfolgreich aus dem Warenkorb entfernt.",
      },
      addItem: {
        content: "Der Artikel wurde erfolgreich zum Warenkorb hinzugefügt.",
      },
      addItems: {
        content: "Die Artikel wurden erfolgreich zum Warenkorb hinzugefügt.",
      },
    },
    priceTagList: {
      addToList: {
        content:
          "Das Produkt wurde erfolgreich zur Etikettenliste hinzugefügt.",
        duration: 1,
      },
      removeFromList: {
        content:
          "Das Produkt wurde erfolgreich von der Etikettenliste entfernt.",
        duration: 1,
      },
      print: {
        content: "Deine Etiketten wurden erfolgreich erstellt.",
      },
      removeAllItems: {
        content: "Die Etikettenliste wurde erfolgreich geleert.",
      },
      autofill: {
        rrPrice: {
          content: "Die UVP Preise wurden erfolgreich eingesetzt",
        },
        rrPriceCounter: {
          content:
            "Die UVP Preise für Thekenware wurden erfolgreich eingesetzt",
        },
        rrCalcPrice: {
          content: "Die kalkul. UVP wurden erfolgreich eingesetzt.",
        },
        rrCalcPriceCounter: {
          content:
            "Die kalkul. UVP für Thekenware wurden erfolgreich eingesetzt.",
        },
        reset: {
          content: "Die Preise wurden erfolgreich geleert.",
        },
      },
      order: {
        content:
          "Die E-Mail zur Schilderbestellung wurde erfolgreich an die Kundenbetreuung gesendet.",
        duration: 4,
      },
    },
    favouriteList: {
      changedLists: {
        content: "Die Auswahl der Favoriten wurde gespeichert.",
        duration: 1,
      },
      addedLists: {
        content: "Die Liste wurde erfolgreich hinzugefügt.",
        duration: 1,
      },
      deletedLists: {
        content: "Die Liste wurde erfolgreich gelöscht.",
        duration: 1,
      },
      createdStandardList: {
        content: "Eine neue Standardliste wurde angelegt.",
        duration: 1,
      },
      addedItemToList: {
        content: "Das Produkt wurde erfolgreich hinzugefügt.",
        duration: 1,
      },
    },
    orders: {
      invoiceDownloadSuccess: {
        content: "Rechnung wurde erfolgreich heruntergeladen.",
      },
      overviewDocumentDownloadSuccess: {
        content: "Vormerkungen wurden erfolgreich heruntergeladen.",
      },
      addPriceTagsToListPriceChangeSuccess: {
        content: "Die Etiketten wurden erfolgreich zur Liste hinzugefügt.",
      },
    },
    products: {
      dnrDataSheetDownloadSuccess: {
        content: "DataNatuRe Datenblatt wurde erfolgreich heruntergeladen.",
      },
    },
    stocktakings: {
      createSuccess: {
        content: "Inventur wurde erfolgreich angelegt.",
      },
      updateSuccess: {
        content: "Inventur wurde erfolgreich gespeichert.",
      },
      deleteSuccess: {
        content: "Inventur wurde erfolgreich gelöscht.",
      },
      downloadSuccess: {
        content: "Inventur wurde erfolgreich heruntergeladen.",
      },
    },
    stocktakingItems: {
      createSuccess: {
        content: "Artikel wurde erfolgreich hinzugefügt.",
      },
      updateSuccess: {
        content: "Artikel wurde erfolgreich gespeichert.",
      },
      deleteSuccess: {
        content: "Artikel wurde erfolgreich gelöscht.",
      },
    },
    companyBusinessUnit: {
      updateSuccess: "Einstellungen wurden gespeichert.",
    },
    quickOrder: {
      fileSuccess: {
        content:
          "Die Artikel wurden erfolgreich zum Warenkorb für den {{deliveryDate}} hinzugefügt.",
      },
      fileNewDeliveryDateSuccess: {
        content:
          "Da das Lieferdatum in der Datei ungültig ist, wurde das nächstmögliche Lieferdatum gewählt. Die Artikel wurden erfolgreich zum Warenkorb für den {{deliveryDate}} hinzugefügt.",
        duration: 5,
      },
    },
    bnn: {
      success: {
        content: "Diese BNN Preisliste wurde erfolgreich heruntergeladen.",
      },
    },
  },
  warning: {
    noProductResults: {
      content: "Es konnten leider keine Produkte gefunden werden.",
    },
    noSearchResults: {
      content:
        "Es konnten leider keine Produkte für die Suchanfrage geladen werden!",
    },
    noSearchResultsDetail: {
      content:
        "Es werden keine Produkte zur Anfrage {{queryString}} zum {{queryDate}} gefunden.",
    },
    noResultsForFilter: {
      content:
        "Es konnten keine Produkte mit diesen Kritierien gefunden werden.",
    },
    noOrders: {
      content: "Es konnten leider keine Bestellungen geladen werden.",
    },
    cancelRequest: {
      content: "Der Vorgang wurde abgebrochen.",
    },
    moodle: {
      auth: {
        content: "Akademie Inhalte konnten nicht geladen werden.",
      },
      noCategories: {
        content: "Es konnten keine Akademie Kategorien geladen werden.",
      },
      noCourses: {
        content: "Es konnten keine Seninare geladen werden.",
      },
    },
    podcast: {
      missingContent: {
        content: "Es konnten nicht alle Podcasts geladen werden",
      },
    },
    noNews: {
      content: "Es konnten leider keine News geladen werden.",
    },
    scanner: {
      accessDenied: {
        className: "scannerMessage",
        content:
          "Die Kamera wurde nicht freigegeben oder es ist keine Kamera vorhanden.",
        duration: 3,
      },
    },
    favouriteList: {
      itemAlreadyAdded: {
        content:
          "Das ausgewählte Produkt befindet sich bereits auf dieser Liste.",
      },
    },
    invalidDate:
      "Das gewünschte Lieferdatum ist nicht verfügbar. Das nächstmögliche Lieferdatum ist ausgewählt.",
    priceTagList: {
      content:
        "Zu diesem Preisdatum konnten nicht alle Preise ermittelt werden. Versuchen Sie es mit einem Datum, das näher in der Zukunft liegt.",
      duration: 6,
    },
  },
  error: {
    unexpected: {
      content: "Ein unerwarteter Fehler ist aufgetreten.",
      duration: 5,
    },
    checkoutFailure: {
      content:
        "Die Bestellung konnte nicht abgesendet werden. Bitte zu einem späteren Zeitpunkt erneut versuchen.",
    },
    termsAndConditionsAcceptance: {
      content:
        "Leider ist bei der Bestätigung der AGB ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    },
    invoiceUnavailable: {
      content: "Die Rechnung konnte leider nicht angezeigt werden.",
    },
    scanner: {
      scanError: {
        className: "scannerMessage",
        content: "Dieser Barcode wurde leider nicht erkannt.",
      },
      notFound: {
        className: "scannerMessage",
        content: "Es konnte leider kein Produkt gefunden werden.",
      },
      revertScan: {
        className: "scannerMessage",
        content:
          "Die ursprüngliche Menge konnte nicht wiederhergestellt werden.",
      },
    },
    auth: {
      login: {
        content:
          "Login fehlgeschlagen. Bitte überprüfen Sie E-Mail und Passwort.",
        duration: 5,
      },
      getBusinessUnit: {
        content:
          "Es konnten keine Filialen gefunden werden. Bitte kontaktieren Sie das Weiling-Team",
        duration: 5,
      },
      setBusinessUnit: {
        content: "Die Filiale konnte nicht gesetzt werden.",
      },
      setPassword: {
        content:
          "Passwortänderung fehlgeschlagen! Bitte überprüfen Sie, ob Sie den richtigen Link geöffnet haben. Sie können auch einen neuen Link anfordern.",
        duration: 5,
      },
      resetPassword: {
        content: "Zurücksetzen fehlgeschlagen.",
      },
      updatePassword: {
        content:
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
        duration: 3,
      },
      resendRegisterConfirmation: {
        content:
          "Es konnte keine E-Mail zur Bestätigung der Registrierung versendet werden. Bitte versuchen Sie es später erneut.",
      },
      loginRequest: {
        content:
          "Ihre Anfrage auf einen Zugang zum Weiling-Portal konnte nicht verarbeitet werden. Bitte versuchen Sie es später erneut.",
        duration: 8,
      },
    },
    cart: {
      addAllAlternatives: {
        content:
          "Beim Hinzufügen der Alternativprodukte ist ein Fehler aufgetreten.",
      },
      deleteMessage: {
        content: "Der Eintrag konnte nicht gelöscht werden.",
      },
      notFound: {
        content: "Der zu löschende Warenkorb existiert nicht mehr.",
      },
      deleteCart: {
        content: "Der aktuelle Warenkorb konnte nicht gelöscht werden.",
      },
      deleteItem: {
        content: "Der Artikel konnte nicht gelöscht werden.",
      },
      unavailableItem: {
        content:
          "Der Warenkorb enthält nicht verfügbare Artikel. Bitte überprüfen Sie Ihre Bestellung.",
        duration: 6,
      },
      addItem: {
        content:
          "Der Artikel konnte leider nicht zum Warenkorb hinzugefügt werden.",
      },
      addItems: {
        content:
          "Die Artikel konnten leider nicht zum Warenkorb hinzugefügt werden.",
      },
    },
    priceTagList: {
      print: {
        content: "Die gewünschten Etiketten konnten nicht erstellt werden.",
      },
      order: {
        content:
          "Die E-Mail zur Schilderbestellung konnte nicht an die Kundenbetreuung gesendet werden.",
        duration: 4,
      },
      updatePrice: {
        content: "Der Preis konnte leider nicht aktualisiert werden.",
      },
    },
    favouriteList: {
      deleteMessage: {
        content: "Die Liste konnte nicht gelöscht werden.",
      },
      selectedMessage: {
        content:
          "Die Auswahl konnte nicht gespeichert werden. Bitte treffen Sie Ihre Auswahl erneut.",
      },
      addedProduct: {
        content: "Das Produkt konnte der Liste nicht hinzugefügt werden.",
      },
    },
    orders: {
      invoiceNotAvailableError: {
        content: "Diese Rechnung ist leider nicht verfügbar.",
      },
      overviewDocumentDownloadError: {
        content: "Vormerkungen sind leider nicht verfügbar.",
      },
      unavailablePriceChangeError: {
        content: "Es konnten keine Preisänderungen ermittelt werden.",
      },
      addPriceTagsToListPriceChangeError: {
        content: "Die Etiketten konnten nicht zur Liste hinzugefügt werden.",
      },
    },
    products: {
      dnrDataSheetNotAvailableError: {
        content: "DataNatuRe Datenblatt ist leider nicht verfügbar.",
      },
    },
    stocktakings: {
      createError: {
        content: "Inventur konnte nicht angelegt werden.",
      },
      readError: {
        content: "Inventur konnte nicht geladen werden.",
      },
      updateError: {
        content: "Inventur konnte nicht gespeichert werden.",
      },
      deleteError: {
        content: "Inventur konnte nicht gelöscht werden.",
      },
      downloadError: {
        content: "Inventur konnte nicht heruntergeladen werden.",
      },
    },
    stocktakingItems: {
      createError: {
        content: "Artikel konnten nicht hinzugefügt werden.",
      },
      readError: {
        content: "Artikel konnten nicht geladen werden.",
      },
      updateError: {
        content: "Artikel konnte nicht gespeichert werden.",
      },
      deleteError: {
        content: "Artikel konnte nicht gelöscht werden.",
      },
    },
    companyBusinessUnit: {
      readError: "Einstellungen konnten nicht geladen werden.",
      updateError: "Einstellungen konnten nicht gespeichert werden.",
    },
    quickOrder: {
      fileUploadError: {
        content: "Die Datei konnte nicht hochgeladen werden.",
      },
      fileAddItems: {
        content: "Die Artikel konnten nicht zum Warenkorb hinzugefügt werden.",
      },
    },
    bnn: {
      notFound: {
        content: "Diese BNN Preisliste ist aktuell nicht verfügbar.",
      },
    },
    weekplanner: {
      loadProducts: {
        content: "Es konnten keine Artikel geladen werden.",
        duration: 5,
      },
    },
  },
  loading: {
    scanner: {
      revertScan: {
        className: "scannerMessage",
        content: "Die ursprüngliche Menge wird wiederhergestellt.",
      },
    },
    priceTagList: {
      print: {
        content: "Deine Etiketten werden vorbereitet.",
        duration: 0,
      },
    },
    businessUnit: {
      load: {
        content: "Filiale wird ausgewählt.",
      },
      switch: {
        content: "Filiale wird gewechselt.",
      },
    },
    logout: {
      content: "Sie werden abgemeldet.",
    },
  },
  info: {
    orders: {
      noPriceChangeInfo: {
        content:
          "Seit der letzten Bestellung gibt es keine Preisänderungen. " +
          "Es wurden keine Etiketten zur Liste hinzugefügt.",
      },
    },
    quickOrder: {
      notFoundItem: {
        content: "Es konnte leider kein passender Artikel gefunden werden.",
        key: "quick_order_not_found_item",
      },
      fileNoItems: {
        content: "Die Datei enthält leider keine verarbeitbaren Artikel.",
      },
      maxItems: {
        content:
          "Sie können max. 100 Artikel per Schnellbestellung auf einmal zum Warenkorb hinzufügen.",
      },
    },
  },
};

export const defaultNewsCategoryTitle = {
  default: "Alle Themen",
  userPreferences: "Meine News",
};

export const categoryPanelData = {
  weekPlanner: ["Wochenplaner", "wochenplaner"],
  products: ["Produkte", "produkte"],
};

// unit of measurement for brix value
export const brixUnit = "°BX";

/*
 * HTML page title settings
 */
export const pageTitleAppName = "Weiling-Portal";
export const pageTitleSeparator = "|";
export const pageTitleSuffix = `${pageTitleSeparator} ${pageTitleAppName}`;
export const pageTitles = {
  login: "Login",
  dashboard: "Dashboard",
  shop: "Webshop",
  priceTagList: "Etikettenliste",
  favouriteList: "Favoritenliste",
  orders: "Meine Bestellungen",
  order: "Bestellung",
  orderConfirmation: "Danke",
  cart: "Warenkorb",
  carts: "Offene Warenkörbe",
  weekPlanner: "Wochenplaner",
  productList: "Produktliste",
  userAccount: "Mein Konto",
  userProfilesAdministration: "Nutzerverwaltung",
  infoCenter: "Infothek",
  privacy: "Datenschutz",
  imprint: "Impressum",
  termsAndConditions: "AGB",
  maintenance: "Wartung",
  orderingInformation: "Bestellinformationen",
  standingOrder: "Daueraufträge",
  stocktakings: "Inventurübersicht",
  stocktaking: "Inventurliste",
  support: "Hilfe & Support",
  impulse: "Impulse",
  loginRequest: "Zugang anfordern",
  productDetail: "Produktdetail",
  resetPassword: "Passwort vergessen",
  quickOrder: "Schnellbestellung",
  notFound: "Seite nicht gefunden",
  downloads: "Downloads",
  contact: "Kontakt",
};

/*
 * Features
 */

export const features = {
  brands: false,
};

/*
 * Misc settings
 */
export const emailAddresses = {
  feedback: "feedback.portal@weiling.de",
};

export const deliverDateOptions = [
  { label: "Montag", value: "1" },
  { label: "Dienstag", value: "2" },
  { label: "Mittwoch", value: "3" },
  { label: "Donnerstag", value: "4" },
  { label: "Freitag", value: "5" },
  { label: "Samstag", value: "6" },
  { label: "Sonntag", value: "0", disabled: true },
];

export const timeouts = {
  scrollToTop: 200,
};

export const delays = {
  searchFilter: 800,
  updateCartItemQuantity: 600,
  stocktakingProductSearch: 800,
  openSearchInput: 200,
  maintenance: 5000,
  updatePriceTagProduct: 800,
};

export const cacheTtls = {
  podcast: 3600,
};

export const contentfulDownloadableFileExtensions = [
  "ZIP",
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
];
