import axiosWithAuth from "../axiosWithAuth";
import { cartQueryString, cartsEndpoint } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import { UpdateCartData } from "../../types/cartAPI";

/**
 * POST item in spryker cart
 * @param cartId {string}
 * @param deliveryDate {string}
 * @param sku {string|number}
 * @param quantity {string|number}
 * @param cancelTokenSource {CancelTokenSource}
 * @param skipMlw {boolean}
 * @return {Promise<AxiosResponse>}
 */
const postItemToCart = async ({
  deliveryDate,
  sku,
  quantity,
  cancelTokenSource,
  skipMlw = false,
}: UpdateCartData) => {
  if (!deliveryDate) {
    return Promise.reject();
  }

  return axiosWithAuth()
    .post(
      `${cartsEndpoint}/${deliveryDate}/items${cartQueryString}${getDeliveryDateQuery(
        deliveryDate
      )}${skipMlw ? "&skipMlw=1" : ""}`,
      {
        data: {
          type: "items",
          attributes: {
            sku: String(sku),
            quantity: String(quantity),
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        return response;
      }

      return Promise.reject(response);
    });
};

export default postItemToCart;
