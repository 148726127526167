import { CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext } from "react";
import deleteItemInCart from "../api/cart/deleteItemInCart";
import patchItemInCart from "../api/cart/patchItemInCart";
import postItemToCart from "../api/cart/postItemToCart";
import updateLocalCartData from "../state/actions/updateLocalCartData";
import { WeekdayData } from "../utils/createWeekplannerDates";
import createWeekplannerCartDataSet from "../utils/createWeekplannerCartDataSet";
import AddToCartDispatch from "../components/product/ProductTile/ProductTileAddToCartContext";

interface UpdateCartItem {
  cancelTokenSource: CancelTokenSource;
  cartId: string;
  deliveryDate?: string;
  sku: string;
  previousQuantity?: string | number;
  quantity: string | number;
  skipMlw?: boolean;
}

const useUpdateCartItemQuantity = () => {
  const dispatch = useDispatch();
  const productBasketContext = useContext(AddToCartDispatch);
  const { id: currentReduxCartId, deliveryDate: currentReduxCartDeliveryDate } =
    useSelector((state: any) => state.currentCartMetaData);
  const { weekDates } = useSelector((state: any) => state.weekplannerData);

  /**
   * handler to update api & redux cart items
   * @param deliveryDate {string}
   * @param cartId {string}
   * @param sku {string}
   * @param quantity {string | number}
   * @param previousQuantity {string | number}
   * @param cancelTokenSource {CancelTokenSource}
   * @param skipMlw {boolean}
   */
  const updateCartItemQuantity = useCallback(
    async function updateCartItemQuantity({
      cancelTokenSource,
      cartId,
      deliveryDate,
      sku,
      previousQuantity = null,
      quantity,
      skipMlw = false,
    }: UpdateCartItem) {
      /*
       * if the quantity is 0 or null, delete the item
       * else if a previous quantity is given, the item is already present, so only update the quantity
       * else post the quantity and therefore make sure the item exists or gets created
       */
      let cartResponse: any;

      if (!Number(quantity)) {
        cartResponse = await deleteItemInCart({
          cancelTokenSource,
          deliveryDate,
          sku,
        });
      } else if (Number(previousQuantity) > 0 && quantity) {
        cartResponse = await patchItemInCart({
          cancelTokenSource,
          deliveryDate,
          quantity,
          sku,
          skipMlw,
        });
      } else {
        cartResponse = await postItemToCart({
          cancelTokenSource,
          deliveryDate,
          quantity,
          sku,
          skipMlw,
        });
      }

      const {
        config: { method },
        status,
        data,
      } = cartResponse;

      // if not valid response, ignore
      if (status >= 300) {
        return Promise.reject(cartResponse);
      }

      const isReduxCartDeliveryDate =
        currentReduxCartDeliveryDate === deliveryDate;
      const isReduxCartId = currentReduxCartId === cartId;

      // check if a deliveryDate is in current Weekplanner set
      const deliveryDateIsInWeekplanner = weekDates.find(
        (weekCart: WeekdayData) => weekCart.apiDate === deliveryDate
      );

      if (isReduxCartDeliveryDate || isReduxCartId) {
        // if the cart item gets deleted remove it from redux
        if (method.toLowerCase() === "delete") {
          dispatch({
            type: "cart/item-remove",
            payload: sku,
          });
        }

        // cart is updated, make sure to check if the update concerns the current redux cart
        if (
          (status === 200 || status === 201) &&
          data?.included &&
          data?.data?.attributes
        ) {
          updateLocalCartData(data.data, cartResponse?.headers?.etag);

          dispatch({
            type: "cart/set-cart",
            payload: {
              cartId: data?.data?.id,
              included: data?.included || [],
              companyBusinessUnitKey:
                data?.data?.attributes?.companyBusinessUnitKey || "",
              employeeDiscount: data?.data?.attributes?.employeeDiscount,
            },
          });
        }
      }

      // update also weekplanner
      if (deliveryDateIsInWeekplanner) {
        if (method === "delete") {
          dispatch({
            type: "weekplanner/delete-item",
            payload: {
              id: cartId,
              sku,
            },
          });
        } else {
          dispatch({
            type: "weekplanner/update-cart",
            payload: createWeekplannerCartDataSet(data?.data),
          });
        }
      }

      // if the product basket uses a context, fire the callback here
      if (productBasketContext !== null) {
        productBasketContext({
          cartResponse,
          method,
        });
      }

      return Promise.resolve(cartResponse);
    },
    [
      productBasketContext,
      dispatch,
      weekDates,
      currentReduxCartDeliveryDate,
      currentReduxCartId,
    ]
  );

  return updateCartItemQuantity;
};

export default useUpdateCartItemQuantity;
