import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext } from "react";
import { CancelTokenSource } from "axios";

import patchItemInCart from "../api/cart/patchItemInCart";
import updateLocalCartData from "../state/actions/updateLocalCartData";
import AddToCartDispatch from "../components/product/ProductTile/ProductTileAddToCartContext";

interface UpdateCartItem {
  cancelTokenSource: CancelTokenSource;
  cartId: string;
  deliveryDate?: string;
  sku: string;
  employeeDiscount: number;
}

const useUpdateCartItemEmployeeDiscount = () => {
  const dispatch = useDispatch();

  const productBasketContext = useContext(AddToCartDispatch);

  const { id: currentReduxCartId, deliveryDate: currentReduxCartDeliveryDate } =
    useSelector((state: any) => state.currentCartMetaData);

  const updateCartItemEmployeeDiscount = useCallback(
    async function updateCartItemEmployeeDiscount({
      cancelTokenSource,
      cartId,
      deliveryDate,
      sku,
      employeeDiscount,
    }: UpdateCartItem) {
      const cartResponse = await patchItemInCart({
        cancelTokenSource,
        deliveryDate,
        employeeDiscount,
        sku,
        skipMlw: true,
      });

      const {
        config: { method },
        status,
        data,
      } = cartResponse;

      // if not valid response, ignore
      if (status >= 300) {
        return Promise.reject(cartResponse);
      }

      const isReduxCartDeliveryDate =
        currentReduxCartDeliveryDate === deliveryDate;
      const isReduxCartId = currentReduxCartId === cartId;

      if (isReduxCartDeliveryDate || isReduxCartId) {
        if (
          (status === 200 || status === 201) &&
          data?.included &&
          data?.data?.attributes
        ) {
          updateLocalCartData(data.data, cartResponse?.headers?.etag);

          dispatch({
            type: "cart/set-cart",
            payload: {
              cartId: data?.data?.id,
              included: data?.included || [],
              companyBusinessUnitKey:
                data?.data?.attributes?.companyBusinessUnitKey || "",
              employeeDiscount: data?.data?.attributes?.employeeDiscount,
            },
          });
        }
      }

      // if the product basket uses a context, fire the callback here
      if (productBasketContext !== null) {
        productBasketContext({
          cartResponse,
          method,
        });
      }

      return Promise.resolve(cartResponse);
    },
    [
      productBasketContext,
      dispatch,
      currentReduxCartDeliveryDate,
      currentReduxCartId,
    ]
  );

  return updateCartItemEmployeeDiscount;
};

export default useUpdateCartItemEmployeeDiscount;
