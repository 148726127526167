import React, { useContext, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { CancelTokenSource } from "axios";
import {
  ProductCount,
  ProductPackageInfo,
  ProductVolumePrices,
} from "../index";
import { productAttributes } from "../../../api/productAttributes";
import { ProductData } from "../../../types/productData";
import ProductPrices from "../ProductPrices";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import ProductAlreadyOrderedBatch from "../ProductAlreadyOrderedBatch";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import { RootState } from "../../../types/rootState";
import getAlreadyOrderedStorage from "../../../state/actions/getAlreadyOrderedStorage";
import ProductDetailModalContext from "../../../contexts/ProductDetailModalContext";
import useDebounceUpdateItemQuantity from "../../../hooks/useDebounceUpdateItemQuantity";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
}

const ProductBasketAvailable: React.FC<Props> = ({
  deliveryDate,
  productData,
}: Props) => {
  const isInProductDetailModal = useContext(ProductDetailModalContext);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  // props
  const { attributes, prices, sku, availabilities } = productData;

  // pricing attributes
  const { availability, volumePrices, strikePrice, isAvailable } =
    getDeliveryDateBasedAttributes({
      deliveryDate,
      availabilities,
      prices,
    });

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.weighingArticle]: weighingArticle,
    [productAttributes.priceUnitText]: priceUnitText,
    [productAttributes.vatGroup]: vatGroup,
  } = attributes;

  // redux
  const { cartItems } = useSelector((state: any) => state.currentCart);

  const pastOrderedProduct = useSelector((state: RootState) => {
    return getAlreadyOrderedStorage(state, sku, deliveryDate);
  });

  const advertisement = useStockForAttribute(attributes, "advert");
  const [inputHasChanged, setInputHasChanged] = useState(false);
  const { currentCart } = useSelector((state: any) => state);
  const initialNumber = useMemo(() => {
    return Number(
      cartItems?.find((cartEntry: any) => cartEntry.sku === sku)?.quantity || 0
    );
  }, [cartItems, sku]);
  const [selectedNumber, setSelectedNumber] = useState<number>(initialNumber);

  let totalQuantityWithOrders = selectedNumber;

  // product quantities
  const quantity = useMemo(
    () =>
      parseInt(
        cartItems?.find((cartEntry: ProductData) => cartEntry.sku === sku)
          ?.quantity,
        10
      ) || 0,
    [cartItems, sku]
  );

  const setItemQuantity = useDebounceUpdateItemQuantity({
    initialQuantity: initialNumber,
    deliveryDate,
    cartId: currentCart.cartItems.cartId,
    sku,
    setIsLoading: () => {
      setInputHasChanged(true);
    },
    resolve: () => {
      setInputHasChanged(false);
    },
    cancelTokenSource: cancelTokenSource.current,
    skipMlw: true,
  });

  /**
   * update the shown amount
   * @param value {number}
   */
  const handleChange = (value: number) => {
    totalQuantityWithOrders = selectedNumber;
    setSelectedNumber(value || 0);
    setItemQuantity(value);
  };

  return (
    <>
      {volumePrices?.length > 0 && (
        <ProductVolumePrices
          availability={availability || isAvailable}
          basePriceQuantity={weighingArticle === "1" ? 1 : productUnitQuantity}
          className={clsx(strikePrice > 0 && "hasStrikePriceVolumePrices")}
          totalOrderQuantity={selectedNumber}
          volumePrices={volumePrices}
        />
      )}

      <div className="productBasket">
        <div className="productPriceInfo">
          <ProductAlreadyOrderedBatch count={pastOrderedProduct} />
          <ProductPrices
            advertisement={advertisement}
            basePriceQuantity={productUnitQuantity}
            productUnitMeasurement={
              weighingArticle === "1" ? priceUnitText : productUnitMeasurement
            }
            className="productPriceInfo"
            deliveryDate={deliveryDate}
            prices={prices}
            quantity={totalQuantityWithOrders}
            weighingArticle={weighingArticle}
            basePriceUnit={productUnitMeasurement}
            sku={sku}
          />
        </div>

        <ProductPackageInfo
          productUnitMeasurement={productUnitMeasurement}
          productUnitQuantity={productUnitQuantity}
          basePriceFactor={basePriceFactor}
          basePriceUnit={basePriceUnit}
          prices={prices}
          deliveryDate={deliveryDate}
          quantity={totalQuantityWithOrders}
          weighingArticle={weighingArticle}
          showProductUVP
          sku={sku}
          vatGroup={vatGroup}
          basePriceQuantity={productUnitQuantity}
        />

        {!isInProductDetailModal && (
          <div className="addToCart">
            <div
              className={clsx(
                "remember-cart",
                !!quantity && "remember-cart-green"
              )}
            >
              <ProductCount
                value={selectedNumber}
                highlightThreshold={0}
                handleChange={handleChange}
                containerClassName="weekplannerCellProductCount"
                className="weekplannerCellProductCountInner"
                useAddons
                isLoading={inputHasChanged}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductBasketAvailable;
